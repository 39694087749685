<template>
  <div>
    <v-form class="ml-2" ref="form">
      <v-row>
        <v-col cols="6">
          <div class="c-one-title">颜色配置</div>
          <color-picker
              @change="Object.assign(item, $event)"
              @resetColor="item[$event] = o_item[$event]"
              :bg-color="item.bgColor"
              :text-color="item.textColor"
              :theme-color="item.themeColor"/>
        </v-col>
        <v-col cols="6">
          <div class="c-one-title mb-3">其他图片</div>
          <v-row>
            <v-col cols="4">
              <div class="c-one-sub-title">个人中心背景图</div>
              <div style="font-size: 12px; color: #999999">
                图片建议：宽高750*468像素，大小不能超过2MB，格式仅限于jpg、png
              </div>
              <material
                  :showAddIcon="false"
                  @change="item.personalPicUrl = $event"
                  :value-image="item.personalPicUrl"
              />
            </v-col>
            <v-col cols="4">
              <div class="c-one-sub-title">分享封面图</div>
              <div style="font-size: 12px; color: #999999">
                图片建议：宽高500*400像素，大小不能超过2MB，格式仅限于jpg、png
              </div>
              <material
                  :value-image="item.sharePicUrl"
                  @change="item.sharePicUrl = $event"
                  :showAddIcon="false"/>
            </v-col>
            <v-col cols="4">
              <div class="c-one-sub-title">登录页背景图</div>
              <div style="font-size: 12px; color: #999999">
                图片建议：宽高600*600像素，大小不能超过2MB，格式仅限于jpg、png
              </div>
              <material
                  :value-image="item.loginPicUrl"
                  @change="item.loginPicUrl = $event"
                  :showAddIcon="false"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-footer
        style="background: #ffffff; padding: 20px 12px"
        padless
        absolute
    >
      <v-btn @click="reset" depressed outlined color="primary">重置</v-btn>
      <v-btn class="ml-4" @click="save" depressed color="primary">保存</v-btn>
    </v-footer>
  </div>
</template>

<script>
import colorPicker from "./colorPicker/index.vue";
import material from "@/components/common-material/material.vue";

export default {
  components: {
    colorPicker,
    material
  },
  data() {
    return {
      o_item: {},
      item: {}
    }
  },
  methods: {
    reset() {
      this.item = JSON.parse(JSON.stringify(this.o_item))
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {type: 'M', mobileConfig: this.item}).then(res => {
        this.snackbar.success('保存成功');
      })
    }
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {type: 'M'}).then(res => {
      this.item = res.data.mobileConfig
      this.o_item = JSON.parse(JSON.stringify(this.item))
    })
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin: 8px 0;
}

::v-deep {

}
</style>
