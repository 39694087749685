<template>
	<div>
		<div class="d-flex align-center mt-5">
			<div style="font-size: 14px" class="font-weight-bold">主题颜色</div>
			<div class="d-color-text">{{ style.bgColor }}</div>
			<div
					class="d-reset"
					@click="$emit('resetColor', 'bgColor')">
				重置
			</div>
			<div
					@click="dialog = true; activeColor = 'bgColor'" class="d-color-box"
					:style="{background: style.bgColor}"></div>
		</div>
		<div class="d-flex align-center mt-5">
			<div style="font-size: 14px" class="font-weight-bold">背景颜色</div>
			<div class="d-color-text">{{ style.themeColor }}</div>
			<div
					class="d-reset"
					@click="$emit('resetColor', 'themeColor')">
				重置
			</div>
			<div
					@click="dialog = true; activeColor = 'themeColor'" class="d-color-box"
					:style="{background: style.themeColor}"></div>
		</div>
		<div class="d-flex align-center mt-5" v-if="showTextColor">
			<div style="font-size: 14px" class="font-weight-bold">框外文本</div>
			<div class="d-color-text">{{ style.textColor }}</div>
			<div
					class="d-reset"
					@click="$emit('resetColor', 'textColor')">
				重置
			</div>
			<div
					@click="dialog = true; activeColor = 'text'" class="d-color-box"
					:style="{background: style.textColor}"></div>
		</div>

		<exhibition-data-box
				title="选取颜色"
				:width="320"
				:dialog.sync="dialog">
			<v-color-picker
					dot-size="15"
					mode="hexa"
					show-swatches
					:value="colorValue"
					@update:color="change"
					swatches-max-height="170"
			></v-color-picker>
		</exhibition-data-box>
	</div>
</template>

<script>
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";

export default {
	components: {
		exhibitionDataBox
	},
	props: {
		// 主题色
		themeColor: {
			type: String,
			default: '#999999'
		},
		// 背景色
		bgColor: {
			type: String,
			default: '#999999'
		},
		textColor: {
			type: String,
			default: '#999999'
		},
		showTextColor: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			dialog: false,
			activeColor: '',
			style: {
				// 主题色
				themeColor: '',
				// 背景色
				bgColor: '',
				textColor: ''
			}
		}
	},
	computed: {
		colorValue() {
			switch (this.activeColor) {
				case 'themeColor':
					return this.style.themeColor
				case 'bgColor':
					return this.style.bgColor
				case 'text':
					return this.style.textColor
			}
			return '#666666'
		}
	},
	methods: {
		change(v) {
			switch (this.activeColor) {
				case 'themeColor':
					this.style.themeColor = v.hex
					break;
				case 'bgColor':
					this.style.bgColor = v.hex
					break;
				case 'text':
					this.style.textColor = v.hex
					break;
			}
		}
	},
	watch: {
		themeColor: {
			handler(v) {
				this.style.themeColor = v
			},
			immediate: true
		},
		bgColor: {
			handler(v) {
				this.style.bgColor = v
			},
			immediate: true
		},
		textColor: {
			handler(v) {
				this.style.textColor = v
			},
			immediate: true
		},
		style: {
			handler(v) {
				this.$emit('change', v)
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.d-reset {
	color: var(--v-primary-base);
	font-size: 12px;
	margin-left: 180px;
	cursor: pointer;
}

.d-color-text {
	margin-left: 34px;
	font-size: 12px;
	width: 62px;
}

.d-color-box {
	width: 64px;
	height: 32px;
	margin-left: 10px;
	border-radius: 4px;
}
</style>
